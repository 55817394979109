import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router';
import CalendarApp from '../calendar/app';

const target = document.getElementById('calendar-app');
ReactDOM.render(
  <StrictMode>
    <Router>
      <Routes>
        <Route path="/feeds/:uid.html" element={<CalendarApp />} />
        <Route path="/embed/:uid.html" element={<CalendarApp />} />
      </Routes>
    </Router>
  </StrictMode>,
  target
);
